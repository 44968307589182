<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-full sm:w-64 flex-shrink-0 border-b pb-4 sm:border-0 sm:pb-0">
      <label class="label" for="phone">Instagram</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! edit" class="w-full flex items-center justify-end py-3 sm:py-0">
        <template>
          <div v-if="accounts.length" class="flex mr-4 items-center">
            <span class="ml-2 font-medium">
              {{ accounts.length }} {{ accounts.length == 1 ? 'account' : 'accounts' }} connected
            </span>
          </div>
          <div v-else>
            <span class="text-gray-500">
              No accounts connected
            </span>
          </div>
        </template>

        <template v-if="status == 'active'">
          <button @click.prevent="edit = true" class="ml-8 flex items-center text-pink-500">
            <EditAltIcon class="w-5 h-5 connect-color" />
            <span class="ml-2 font-light connect-color">Edit</span>
          </button>
        </template>
        <template v-else>
          <a :href="instagramDialogUrl" class="sm:ml-8 flex items-center text-pink-500" target="_blank" rel="noopener">
            <LinkIcon class="w-5 h-5 connect-color" />
            <span class="ml-2 font-light connect-color">Connect</span>
          </a>
        </template>
      </div>
      <div v-else class="w-full mt-4 sm:mt-0">
        <ValidationObserver ref="googleForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(saveIntegration)" method="post">
            <template>
              <div class="flex items-center justify-between">
                <span class="font-semibold">Account</span>
                <span>
                  {{ integration.account || '-' }}
                </span>
              </div>

              <div class="mt-6">
                <div class="flex items-center justify-between">
                  <div class="font-semibold">Available Instagram Accounts</div>

                  <div>
                    <template v-if="refreshing">
                      <loading-icon class="h-1 text-pink-500" />
                    </template>

                    <template v-else>
                      <button @click="refreshAccounts" type="button" class="text-indigo-500 text-sm font-medium">
                        Refresh Accounts
                      </button>
                    </template>
                  </div>
                </div>

                <div class="mt-1 flex-1 bg-gray-50 rounded shadow p-4 flex flex-col items-start space-y-4">
                  <template v-if="availableAccounts.length">
                    <div v-for="account in availableAccounts" :key="`account-${account.id}`" class="w-full">
                      <label class="cursor-pointer select-none flex w-full items-center">
                        <input type="checkbox" class="form-checkbox h-5 w-5 text-pink-500" :value="account.id" v-model="form.params.accounts">
                        <div class="ml-4 flex items-center justify-between w-full">
                          <span class="flex items-center">
                            <img :src="account.profile_picture_url || '/images/test_image.png'" class="h-6 w-6 rounded-full shadow-sm">
                            <span class="ml-2 font-medium">
                              {{ account.name }}
                            </span>
                          </span>
                          <span class="ml-2 text-sm text-gray-500">
                            {{ formatNumber(account.followers_count) }} followers
                          </span>
                        </div>
                      </label>
                    </div>
                  </template>

                  <div v-if="! availableAccounts.length" class="h-14 bg-gray-50 border-2 border-dashed flex items-center justify-center">
                    No Instagram accounts available.
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:mt-6 flex flex-col sm:flex-row items-center justify-between pb-6">
                <div>
                  <alert-dialog
                    v-if="status == 'active'"
                    class="w-full rounded-full py-3 text-center text-gray-500"
                    confirm="Unlink Instagram"
                    confirming="Unlinking"
                    @confirm="disableIntegration"
                  >
                    <span>Unlink</span>
                    <span slot="title">Unlink Intagram?</span>
                    <template v-slot:content>
                      <div>Are you sure you want to unlink Instagram?</div>
                    </template>
                  </alert-dialog>
                </div>
                <div class="flex items-center justify-end mt-6 sm:mt-0">
                  <template v-if="working">
                    <loading-icon class="h-1 text-pink-500" />
                  </template>
                  <template v-else>
                    <button class="detail-cancel-button" :disabled="working" @click.prevent="edit = false">Cancel</button>
                    <button type="submit" class="detail-save-button" :disabled="working">
                      Save
                    </button>
                  </template>
                </div>
              </div>
            </template>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash'
  import api from '@/api'
  import EditAltIcon from '@/components/svgs/EditAltIcon'
  import LinkIcon from '@/components/svgs/LinkIcon'
  import AlertDialog from '@/components/shared/AlertDialog'

  export default {
    props: {
      integration: {
        type: Object,
        default: () => ({})
      }
    },

    components: {
      EditAltIcon,
      LinkIcon,
      AlertDialog,
    },

    data() {
      return {
        working: false,
        refreshing: false,
        edit: false,
        form: {
          name: 'Instagram Linked Account',
          slug: 'instagram-linked-account',
          account: '',
          params: {
            accounts: [],
          }
        }
      }
    },

    computed: {
      status() {
        if (this.integration.id) {
          return 'active'
        }

        return 'inactive'
      },

      instagramDialogUrl() {
        return `https://www.facebook.com/v12.0/dialog/oauth?${this.dialogParams}`
      },

      dialogParams() {
        let urlParts = {
          response_type: 'code',
          client_id: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
          redirect_uri: `${process.env.VUE_APP_URL}/instagram/authorization`,
          scope: 'instagram_basic pages_show_list',
        }

        return new URLSearchParams(urlParts)
      },

      accounts() {
        if (! this.integration.params) {
          return []
        }

        return this.integration.params.accounts || []
      },

      availableAccounts() {
        if (! this.integration.data) {
          return []
        }

        return this.integration.data.accounts || []
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      }
    },

    watch: {
      async edit(edit) {
        if (! edit) return

        if (! this.integration.id) {
          return
        }

        this.form.account = this.integration.account
        this.form.params.accounts = this.integration.params?.accounts || []
      }
    },

    methods: {
      refresh() {
        this.$emit('refresh')
      },

      async saveIntegration() {
        this.working = true

        if (this.integration.id) {
          return this.update()
        }

        return this.create()
      },

      update() {
        api
          .put(`/integrations/${this.integration.id}`, this.form)
          .then(({ data }) => {
            this.updateFollowers(data.data)
            this.refresh()
            this.edit = false
          })
          .catch(() => {
            this.$toast.error('Instagram account not updated')
          })
          .finally(() => this.working = false)
      },

      refreshAccounts() {
        this.refreshing = true

        api
          .get(`/instagram/refresh`)
          .then(({ data }) => {
            this.integration = data.data
          })
          .catch(() => {
            this.$toast.error('Instagram accounts not refreshed. If the error persists, try unlinking and reconnecting your account.')
          })
          .finally(() => this.refreshing = false)
      },

      create() {
        api
          .post(`/integrations`, this.form)
          .then(({ data }) => {
            this.updateFollowers(data.data)
            this.refresh()
            this.edit = false
          })
          .catch(() => {
            this.$toast.error('Instagram account not linked')
          })
          .finally(() => this.working = false)
      },

      disableIntegration(callback) {
        api.delete(`/integrations/${this.integration.id}`)
          .then(() => {
            this.removeFollowers()
            this.edit = false
            this.refresh()
            callback.call()
          })
          .catch(() => {
            this.$toast.error('Error! Instagram was not unlinked')
          })
      },

      updateFollowers(data) {
        let accounts = get(data, 'params.accounts', [])

        if (! accounts.length) {
          this.removeFollowers()
          return
        }

        let totalFollowers = get(data, 'data.accounts', [])
          .filter(account => accounts.includes(account.id))
          .reduce((total, account) => {
            return parseInt(account.followers_count) + total
          }, 0)

        this.$store.dispatch('guest/storeProfileReach', {
          guestId: this.guest.id,
          platform: 'instagram',
          followers: totalFollowers,
        })
      },

      removeFollowers() {
        this.$store.dispatch('guest/deleteProfileReach', {
          guestId: this.guest.id,
          platform: 'instagram',
        })
      },

      formatNumber(number) {
        let unitlist = ["","K","M","B"]
        let unit = 0

        while (Math.abs(number) > 1000) {
          unit = unit + 1;
          number = Math.floor(Math.abs(number) / 100)/10;
        }

        return number + unitlist[unit];
      }
    },
  }
</script>

<style scoped>
  .label {
    color: #737373;
    font-weight: 500;
    font-size: 12pt;
  }

  .connect-color {
    color: #B503D0;
  }
</style>