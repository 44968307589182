<template>
  <div>
    <div v-if="! guest.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="mb-4 max-w-3xl w-full">
      <div v-if="instagramConnectEnabled" class="md:py-6 border-b last:border-b-0 border-gray-300">
        <instagram-connect :integration="instagramIntegration" @refresh="fetchIntegrations" />
      </div>

      <div v-if="twitterConnectEnabled" class="md:py-6 border-b last:border-b-0 border-gray-300 mt-8 sm:mt-0">
        <twitter-connect :integration="twitterIntegration" @refresh="fetchIntegrations" />
      </div>

      <div v-if="youtubeConnectEnabled" class="md:py-6 border-b last:border-b-0 border-gray-300 mt-8 sm:mt-0">
        <youtube-connect :integration="youtubeIntegration" @refresh="fetchIntegrations" />
      </div>

      <div v-if="tiktokConnectEnabled" class="md:py-6 border-b last:border-b-0 border-gray-300 mt-8 sm:mt-0">
        <tiktok-connect :integration="tiktokIntegration" @refresh="fetchIntegrations" />
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapState } from "vuex";
import InstagramConnect from './linked-accounts/InstagramConnect'
import TwitterConnect from './linked-accounts/TwitterConnect'
import YoutubeConnect from './linked-accounts/YoutubeConnect'
import TiktokConnect from './linked-accounts/TiktokConnect'

export default {
  components: {
    TwitterConnect,
    InstagramConnect,
    YoutubeConnect,
    TiktokConnect,
  },

  data() {
    return {
      integrations: [],
    }
  },

  computed: {
    ...mapState({
      guest: state => state.guest.guest
    }),

    instagramConnectEnabled() {
      return process.env.VUE_APP_INSTAGRAM_CONNECT_ENABLED == 'true'
    },

    twitterConnectEnabled() {
      return process.env.VUE_APP_TWITTER_CONNECT_ENABLED == 'true'
    },

    youtubeConnectEnabled() {
      return process.env.VUE_APP_YOUTUBE_CONNECT_ENABLED == 'true'
    },

    tiktokConnectEnabled() {
      return process.env.VUE_APP_TIKTOK_CONNECT_ENABLED == 'true'
    },

    instagramIntegration() {
      return this.integrations.find(integration => integration.slug == 'instagram-linked-account')
    },

    twitterIntegration() {
      return this.integrations.find(integration => integration.slug == 'twitter-linked-account')
    },

    youtubeIntegration() {
      return this.integrations.find(integration => integration.slug == 'youtube-linked-account')
    },

    tiktokIntegration() {
      return this.integrations.find(integration => integration.slug == 'tiktok-linked-account')
    },
  },

  methods: {
    fetchIntegrations() {
      api.get('/integrations?filter[slug]=instagram-linked-account,twitter-linked-account,youtube-linked-account,tiktok-linked-account')
        .then(({data}) => {
          this.integrations = data.data
        })
    }
  },

  created() {
    this.fetchIntegrations()
  }
}
</script>
